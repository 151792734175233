// components/coping-strategy-survey/CopingStatementSurvey.jsx

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Typography,
    Button,
    Rating,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { pxToRem } from '../../theme/typography';
import useResponsive from '../../hooks/useResponsive';

// Import custom icons for smiley faces
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

// Import the Editor component
import Editor from '../editor'; // Adjust the import path as necessary
import apiLayer from '../../services/api-layer/ApiLayer';
import { COPING_STRATEGY_TYPES } from '../../constants/coping-strategy-types/index.js';
import { handleError } from '../../utils/handleError.js';

const filename = "src/components/coping-statement-modal/CopingStatementSurvey.js"

const labels = {
    1: '😞 1 - Not at all helpful',
    2: '🙁 2 - Slightly helpful',
    3: '😐 3 - Moderately helpful',
    4: '🙂 4 - Very helpful',
    5: '😃 5 - Extremely helpful',
};

// Styled Rating component to use smiley faces
const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
        label: 'Not at all helpful',
    },
    2: {
        icon: <SentimentDissatisfiedIcon fontSize="large" />,
        label: 'Slightly helpful',
    },
    3: {
        icon: <SentimentNeutralIcon fontSize="large" />,
        label: 'Moderately helpful',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon fontSize="large" />,
        label: 'Very helpful',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon fontSize="large" />,
        label: 'Extremely helpful',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

const CopingStatementSurvey = observer(({ onEnd }) => {
    const isDesktop = useResponsive('up', 'lg');

    const [rating, setRating] = useState(0);
    const [ratingHover, setRatingHover] = useState(-1);
    const [ratingExplanation, setRatingExplanation] = useState('');
    const [additionalFeedback, setAdditionalFeedback] = useState('');

    const handleSubmit = async () => {
        const feedbackData = {
            strategy_type: COPING_STRATEGY_TYPES.COPING_STATEMENT,
            rating,
            rating_explanation: ratingExplanation,
            additional_feedback: additionalFeedback,
        };

        try {

            const res = await apiLayer.postCopingStrategyFeedback(feedbackData);

            if (res.data.err) {
                handleError({
                    error: res.data.err,
                    message: `Error creating coping strategy feedback with data: ${JSON.stringify(feedbackData)}`,
                    filename,
                    functionName: 'handleSubmit',
                });
            }
        } catch (err) {
            handleError({
                error: err,
                message: `Error creating coping strategy feedback with data: ${JSON.stringify(feedbackData)}`,
                filename,
                functionName: 'handleSubmit',
            });
        } finally {
            onEnd();
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: pxToRem(16),
            }}
            data-qa-id="coping-statement-survey"
        >
            {/* End button at the top right */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                data-qa-id="end-button-container"
            >
                <Button
                    onClick={onEnd}
                    sx={{
                        color: 'black',
                        fontWeight: 'bold', // Bold styling applied here
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    marginTop: pxToRem(24),
                    paddingBottom: pxToRem(24),
                }}
                data-qa-id="survey-content"
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold', // Bold styling applied here
                        marginBottom: pxToRem(24),
                    }}
                >
                    We'd love your help in making this feature better.
                </Typography>

                {/* Question 1 */}
                <Typography variant="body1" sx={{ marginBottom: pxToRem(12), fontWeight: 'bold' }}>
                    Overall, how helpful was this exercise in helping you resist ritualizing?
                </Typography>
                <StyledRating
                    name="customized-icons"
                    value={rating}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                        setRatingHover(newHover);
                    }}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value) => customIcons[value].label}
                    highlightSelectedOnly
                    sx={{ fontSize: pxToRem(40), marginBottom: pxToRem(12) }}
                    data-qa-id="rating"
                />
                {/* Dynamic Label for Rating */}
                {rating !== 0 && (
                    <Typography variant="body2" sx={{ marginBottom: pxToRem(24), color: 'text.secondary' }}>
                        {labels[ratingHover !== -1 ? ratingHover : rating]}
                    </Typography>
                )}

                {/* Question 2 */}
                <Typography variant="body1" sx={{ marginBottom: pxToRem(12), marginTop: pxToRem(24), fontWeight: 'bold' }}>
                    Please share why you gave this rating (optional).
                </Typography>
                <Editor
                    id="rating-explanation-editor"
                    value={ratingExplanation}
                    onChange={setRatingExplanation}
                    readOnly={false}
                    toolbarActive={false}
                    autoFocus={false}
                    sx={{
                        p: 1,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        border: '1px solid black',
                        '& .ql-editor': {
                            minHeight: pxToRem(80), // Replaced '80px' with pxToRem(80)
                            '&.ql-blank::before': {
                                left: 0,
                                top: '4px',
                                color: 'gray',
                                opacity: 0.5,
                            },
                        },
                    }}
                    placeholder="Your response"
                    data-qa-id="rating-explanation-editor"
                />

                {/* Question 3 */}
                <Typography variant="body1" sx={{ marginBottom: pxToRem(12), marginTop: pxToRem(24), fontWeight: 'bold' }}>
                    Do you have any additional feedback or suggestions to improve this exercise? (optional)
                </Typography>
                <Editor
                    id="additional-feedback-editor"
                    value={additionalFeedback}
                    onChange={setAdditionalFeedback}
                    readOnly={false}
                    toolbarActive={false}
                    autoFocus={false}
                    sx={{
                        p: 1,
                        borderRadius: 1,
                        backgroundColor: 'white',
                        border: '1px solid black',
                        '& .ql-editor': {
                            minHeight: pxToRem(80), // Replaced '80px' with pxToRem(80)
                            '&.ql-blank::before': {
                                left: 0,
                                top: '4px',
                                color: 'gray',
                                opacity: 0.5,
                            },
                        },
                    }}
                    placeholder="Your feedback"
                    data-qa-id="additional-feedback-editor"
                />

                {/* Submit Button */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: isDesktop ? 'flex-start' : 'center',
                        marginTop: pxToRem(32),
                    }}
                    data-qa-id="submit-button-container"
                >
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{
                            backgroundColor: 'black',
                            fontWeight: 'bold', // Bold styling applied here
                            textTransform: 'none',
                            width: isDesktop ? 'auto' : '100%',
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                        }}
                        data-qa-id="submit-button"
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    );
});

CopingStatementSurvey.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default CopingStatementSurvey;

import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import useResponsive from '../../../hooks/useResponsive';
import { COPING_STATEMENT_SURVEY, REPEAT_COPING_STATEMENT_STEP } from '../../../constants/coping-strategies/coping-statements';
import { COPING_STRATEGY_TYPES } from '../../../constants/coping-strategy-types';
import apiLayer from '../../../services/api-layer/ApiLayer';
import { handleError } from '../../../utils/handleError';

const filename = "src/components/coping-statement-modal/coping-statement-steps/DoneOrRepeatStep.js";

const DoneOrRepeatStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    const handleContinueWithCopingStatement = () => {
        copingStatementStore.goToStep(REPEAT_COPING_STATEMENT_STEP);
    };

    const handleFinish = async () => {
        try {
            const strategy_type = COPING_STRATEGY_TYPES.COPING_STATEMENT;

            const res = await apiLayer.checkCopingStrategySurveyCompletion({ strategy_type });

            console.log("in handle finish res.data: ", res.data);

            if (res.data.hasCompleted) {
                onEnd();
            } else {
                copingStatementStore.goToStep(COPING_STATEMENT_SURVEY);
            }
        } catch (err) {
            handleError({
                error: err,
                message: `Error checking coping strategy survey completion.`,
                filename,
                functionName: 'handleFinish',
            });

            onEnd();
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="deep-breath-step"
        >
            {/* Top bar with End button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={handleFinish}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
                data-qa-id="content-container"
            >
                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                    }}
                    data-qa-id="instruction-text-first"
                >
                    We know this may not have eased your distress, and that’s okay. The goal isn’t to feel a certain way—it’s to resist your compulsions.
                </Typography>

                {/* Add more space between the sentence completion and examples */}
                <Box sx={{ height: 16 }} /> {/* Adds 16px of space */}

                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                    }}
                    data-qa-id="instruction-text-second"
                >
                    Use the coping statements as needed to keep resisting.
                </Typography>


                {isDesktop && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginTop: 4,
                        }}
                        data-qa-id="continue-button-container"
                    >
                        <Button
                            variant="contained"
                            onClick={handleContinueWithCopingStatement}
                            sx={{
                                backgroundColor: 'black',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:disabled': {
                                    backgroundColor: 'grey',
                                    '&::before': {
                                        backgroundColor: 'grey',
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            Continue with coping statement
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleFinish}
                            sx={{
                                backgroundColor: 'black',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                marginTop: 2,
                                '&:disabled': {
                                    backgroundColor: 'grey',
                                    '&::before': {
                                        backgroundColor: 'grey',
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            I'm done
                        </Button>
                    </Box>
                )}
            </Box>

            {!isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: 2,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={handleContinueWithCopingStatement}
                        sx={{
                            backgroundColor: 'black',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:disabled': {
                                backgroundColor: 'grey',
                                '&::before': {
                                    backgroundColor: 'grey',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue with coping statement
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={handleFinish}
                        sx={{
                            backgroundColor: 'black',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            marginTop: 2,
                            '&:disabled': {
                                backgroundColor: 'grey',
                                '&::before': {
                                    backgroundColor: 'grey',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        I'm done
                    </Button>
                </Box>
            )}
        </Box >
    );
});

DoneOrRepeatStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default DoneOrRepeatStep;

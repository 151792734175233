import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, TextField, Chip, IconButton } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import useResponsive from '../../../hooks/useResponsive';
import { pxToRem } from '../../../theme/typography';

const exampleResponses = [
    'my muscles are tensing',
    'a stormy sea',
    'weights on my shoulders',
    'a racing heartbeat',
    'butterflies fluttering',
];

const NoticeFeelingsStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    const [responseContent, setResponseContent] = useState('');

    const handleContinue = () => {
        copingStatementStore.goToNextStep();
    };

    const handleResponseChange = (e) => {
        setResponseContent(e.target.value);
    };

    const handleExampleClick = (example) => {
        setResponseContent(example);
    };

    const handleClearResponse = () => {
        setResponseContent('');
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="deep-breath-step"
        >
            {/* Top bar with End button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={onEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
                data-qa-id="content-container"
            >
                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                    }}
                    data-qa-id="instruction-text"
                >
                    Take a moment to notice how you're feeling.
                </Typography>

                {/* Sentence completion */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: 2,
                        width: "100%",
                    }}
                >
                    <Typography
                        variant={isDesktop ? "h3" : "h4"}
                        align="left"
                        sx={{
                            marginRight: 1,
                        }}
                        data-qa-id="sentence-prompt-text"
                    >
                        It feels like
                    </Typography>
                    <TextField
                        value={responseContent}
                        onChange={handleResponseChange}
                        placeholder="..."
                        variant="standard"
                        autoFocus={true}
                        inputProps={{
                            style: {
                                fontSize: isDesktop ? pxToRem(32) : pxToRem(16),
                                backgroundColor: 'inherit',
                            },
                        }}
                        InputProps={{
                            endAdornment: responseContent && (
                                <IconButton
                                    aria-label="clear response"
                                    onClick={handleClearResponse}
                                    edge="end"
                                    size="small"
                                    sx={{ padding: 0 }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width: 'auto',
                            fontSize: 'inherit',
                            flexGrow: 0.5,
                            '& .MuiInputBase-input': {
                                fontSize: "inherit",
                            },
                        }}
                        data-qa-id="response-input"
                    />
                </Box>

                {/* Add more space between the sentence completion and examples */}
                <Box sx={{ height: 16 }} /> {/* Adds 16px of space */}

                {/* Examples */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        width: '100%',
                    }}
                    data-qa-id="examples-container"
                >
                    {exampleResponses.map((example, index) => (
                        <Chip
                            key={index}
                            label={example}
                            onClick={() => handleExampleClick(example)}
                            sx={{
                                backgroundColor: '#A3FFAB !important',
                                border: responseContent === example ? 'black 2px solid' : 'inherit',
                                fontWeight: 'bold',
                                maxWidth: '100%',
                                whiteSpace: 'normal',
                                height: 'auto',
                                '& .MuiChip-label': {
                                    whiteSpace: 'normal',
                                    overflow: 'visible',
                                    textOverflow: 'clip',
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                },
                            }}
                            data-qa-id={`example-chip-${index}`}
                        />
                    ))}
                </Box>
                {isDesktop && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginTop: 4,
                        }}
                        data-qa-id="continue-button-container"
                    >
                        <Button
                            variant="contained"
                            onClick={handleContinue}
                            disabled={!responseContent.trim()}
                            sx={{
                                backgroundColor: 'black',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:disabled': {
                                    backgroundColor: 'grey',
                                    '&::before': {
                                        backgroundColor: 'grey',
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            Continue
                        </Button>
                    </Box>
                )}
            </Box>

            {!isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={handleContinue}
                        disabled={!responseContent.trim()}
                        sx={{
                            backgroundColor: 'black',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:disabled': {
                                backgroundColor: 'grey',
                                '&::before': {
                                    backgroundColor: 'grey',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue
                    </Button>
                </Box>
            )}
        </Box >
    );
});

NoticeFeelingsStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default NoticeFeelingsStep;

// components/coping-statement-steps/FocusActivityStep/FocusActivityStep.jsx

import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
    Fade,
} from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import { styled, keyframes } from '@mui/material/styles';
import { pxToRem } from '../../../theme/typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { green, red } from '@mui/material/colors';
import { REPEAT_COPING_STATEMENT_STEP } from '../../../constants/coping-strategies/coping-statements';
import useResponsive from '../../../hooks/useResponsive';

// Function to map color names to hex codes
const colorHexMap = {
    Red: '#FF0000',
    Blue: '#0000FF',
    Green: '#008000',
    Yellow: '#FFFF00',
    Black: '#000000',
    White: '#FFFFFF',
};

// Helper functions for accessibility
const getLuminance = (hexColor) => {
    let r = parseInt(hexColor.substr(1, 2), 16) / 255;
    let g = parseInt(hexColor.substr(3, 2), 16) / 255;
    let b = parseInt(hexColor.substr(5, 2), 16) / 255;

    r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
    g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
    b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const getContrastingBackgroundColor = (hexColor) => {
    const luminance = getLuminance(hexColor);

    const contrastWithBlack = (luminance + 0.05) / 0.05;
    const contrastWithWhite = 1.05 / (luminance + 0.05);

    return contrastWithBlack > contrastWithWhite ? '#000000' : '#FFFFFF';
};

// Define keyframes for score animation
const scoreIncrease = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

// Styled components
const StyledCard = styled(Card)(({ theme, isDesktop }) => ({
    position: 'relative',
    width: isDesktop ? '80%' : '100%',
    margin: 'auto',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    flexGrow: '0',
    height: pxToRem(120),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(2),
    position: 'relative',
}));

// FeedbackIcon component
const FeedbackIcon = ({ isCorrect }) => {
    const Icon = isCorrect ? CheckIcon : CloseIcon;
    const backgroundColor = isCorrect ? green[500] : red[500];

    return (
        <Fade in timeout={500}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: pxToRem(80),
                    height: pxToRem(80),
                    borderRadius: '50%',
                    backgroundColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Icon sx={{ fontSize: pxToRem(50), color: '#fff' }} />
            </Box>
        </Fade>
    );
};

FeedbackIcon.propTypes = {
    isCorrect: PropTypes.bool.isRequired,
};

// ColorCard component
const ColorCard = ({
    word,
    color,
    backgroundColor,
    textColor,
    dataQaId,
    isTextHidden,
}) => {
    const cardBackgroundColor =
        backgroundColor ||
        getContrastingBackgroundColor(colorHexMap[color] || '#000000');
    const textColorFinal = textColor || colorHexMap[color] || '#000000';

    const isDesktop = useResponsive('up', 'lg');

    return (
        <StyledCard data-qa-id={dataQaId} sx={{ backgroundColor: cardBackgroundColor }} isDesktop={isDesktop}>
            <StyledCardContent>
                {!isTextHidden ? (
                    <Fade in={!isTextHidden} timeout={500} appear>
                        <Typography
                            variant="h2"
                            align="center"
                            sx={{
                                color: textColorFinal,
                                fontWeight: 'bold',
                            }}
                        >
                            {word}
                        </Typography>
                    </Fade>
                ) : (
                    <Fade in={isTextHidden} timeout={500}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                color: textColorFinal,
                                fontWeight: 'bold',
                            }}
                        >
                            Remember the color
                        </Typography>
                    </Fade>
                )}
            </StyledCardContent>
        </StyledCard>
    );
};

ColorCard.propTypes = {
    word: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    dataQaId: PropTypes.string,
    isTextHidden: PropTypes.bool,
};

// Main component
const FocusActivityStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    // Define the list of colors
    const colors = ['Red', 'Blue', 'Green', 'Yellow'];

    const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

    // Initialize state variables
    const [topCardWord, setTopCardWord] = useState(getRandomColor());
    const [bottomCardWord, setBottomCardWord] = useState(getRandomColor());
    const [bottomCardTextColor, setBottomCardTextColor] = useState(getRandomColor());
    const [topCardBackgroundColor, setTopCardBackgroundColor] = useState(null);
    const [topCardTextColor, setTopCardTextColor] = useState(colorHexMap.Black);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [gameOverReason, setGameOverReason] = useState(null); // 'incorrect' or 'timeout'
    const [answerFeedback, setAnswerFeedback] = useState(null); // 'correct' or 'incorrect'
    const [animateScore, setAnimateScore] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [timerDuration, setTimerDuration] = useState(null);
    const [showGameOverButtons, setShowGameOverButtons] = useState(false);
    const [isTopTextHidden, setIsTopTextHidden] = useState(false);
    const [isBottomTextHidden, setIsBottomTextHidden] = useState(false);

    const timerIdRef = useRef(null);
    const textHideTimeoutRef = useRef(null);
    const previousPairRef = useRef({
        topCardWord: null,
        bottomCardWord: null,
        bottomCardTextColor: null,
    });

    // Function to generate new cards
    const generateNewCards = (currentScore) => {
        setAnswerFeedback(null); // Reset feedback
        setIsTopTextHidden(false); // Reset text hidden state
        setIsBottomTextHidden(false);

        // Clear any existing timers
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }

        // Use the updated score
        const scoreToUse = currentScore !== undefined ? currentScore : score;

        // Increase difficulty by changing the top card's background color after score >= 8
        if (scoreToUse >= 8) {
            const newBackgroundColor = colorHexMap[getRandomColor()];
            setTopCardBackgroundColor(newBackgroundColor);
            setTopCardTextColor(getContrastingBackgroundColor(newBackgroundColor));
        } else {
            setTopCardBackgroundColor(null);
            setTopCardTextColor(colorHexMap.Black);
        }

        // Handle text hiding based on score
        // Handle text hiding based on score
        if (scoreToUse >= 32) {
            // After score >= 32, hide both top and bottom texts after half a second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
                setIsBottomTextHidden(true);
            }, 500);
        } else if (scoreToUse >= 26) {
            // After score >= 26, hide both top and bottom texts after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
                setIsBottomTextHidden(true);
            }, 1000);
        } else if (scoreToUse >= 22) {
            // After score >= 22, hide bottom text after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsBottomTextHidden(true);
            }, 1000);
        } else if (scoreToUse >= 16) {
            // After score >= 16, hide top text after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
            }, 1000);
        }

        // Update timer duration based on the new score
        let duration = null;
        if (scoreToUse >= 3) {
            if (scoreToUse >= 36) {
                duration = 1;
            } else if (scoreToUse >= 26) {
                duration = 2;
            } else if (scoreToUse >= 16) {
                duration = 3;
            } else if (scoreToUse >= 8) {
                duration = 4;
            } else {
                duration = 6;
            }
        }
        setTimerDuration(duration);
        setTimeLeft(duration);

        // Generate new cards, ensuring they are not the same as the previous pair
        const maxAttempts = 10;
        let attempts = 0;
        let newTopCardWord, newBottomCardWord, newBottomCardTextColor;

        do {
            newTopCardWord = getRandomColor();
            newBottomCardWord = getRandomColor();
            newBottomCardTextColor = getRandomColor();
            attempts++;
        } while (
            attempts < maxAttempts &&
            newTopCardWord === previousPairRef.current.topCardWord &&
            newBottomCardWord === previousPairRef.current.bottomCardWord &&
            newBottomCardTextColor === previousPairRef.current.bottomCardTextColor
        );

        setTopCardWord(newTopCardWord);
        setBottomCardWord(newBottomCardWord);
        setBottomCardTextColor(newBottomCardTextColor);

        // Update the previous pair
        previousPairRef.current = {
            topCardWord: newTopCardWord,
            bottomCardWord: newBottomCardWord,
            bottomCardTextColor: newBottomCardTextColor,
        };
    };

    // Function to reset the game state
    const resetGame = () => {
        // Clear any running timers
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
            timerIdRef.current = null;
        }
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }
        setScore(0);
        setGameOver(false);
        setGameOverReason(null);
        setTimeLeft(null);
        setTimerDuration(null);
        setAnimateScore(false);
        setShowGameOverButtons(false);
        setTopCardBackgroundColor(null);
        setTopCardTextColor(colorHexMap.Black);
        setIsTopTextHidden(false);
        setIsBottomTextHidden(false);
        previousPairRef.current = {
            topCardWord: null,
            bottomCardWord: null,
            bottomCardTextColor: null,
        };
        generateNewCards(0);
    };

    // Initialize the game
    useEffect(() => {
        resetGame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Timer countdown
    useEffect(() => {
        if (timeLeft === null || gameOver) {
            return;
        }

        if (timeLeft <= 0) {
            // Time ran out
            setAnswerFeedback('incorrect');
            setGameOverReason('timeout');
            setGameOver(true);

            // Reveal hidden texts
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);
        } else {
            timerIdRef.current = setTimeout(() => {
                setTimeLeft((prevTime) => {
                    const newTime = parseFloat((prevTime - 0.1).toFixed(1));
                    return newTime >= 0 ? newTime : 0;
                });
            }, 100);
        }

        // Cleanup function
        return () => {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current);
            }
        };
    }, [timeLeft, gameOver]);

    // Show game over buttons after a delay if timeout
    useEffect(() => {
        if (gameOver) {
            // Reveal hidden texts when game is over
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);

            if (gameOverReason === 'timeout') {
                // Delay only if the game over reason is timeout
                const timeoutId = setTimeout(() => {
                    setShowGameOverButtons(true);
                }, 2000); // 2-second delay

                return () => clearTimeout(timeoutId);
            } else {
                // Show buttons immediately if the game ended due to incorrect answer
                setShowGameOverButtons(true);
            }
        }
    }, [gameOver, gameOverReason]);

    // Function to handle user answer
    const handleAnswer = (userAnswer) => {
        if (gameOver) return;

        // Clear timers when user answers
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
            timerIdRef.current = null;
        }
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }

        const correctAnswer = topCardWord === bottomCardTextColor;
        if (userAnswer === correctAnswer) {
            // Correct answer
            setAnswerFeedback('correct');
            setScore((prevScore) => {
                const newScore = prevScore + 1;
                setAnimateScore(true);

                // Proceed to next question after a short delay
                setTimeout(() => {
                    generateNewCards(newScore);
                }, 800);

                return newScore;
            });
        } else {
            // Incorrect answer
            setAnswerFeedback('incorrect');
            setGameOverReason('incorrect');
            setGameOver(true);

            // Reveal hidden texts
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);
        }
    };

    // Animate score when it increases
    useEffect(() => {
        if (animateScore) {
            const timer = setTimeout(() => setAnimateScore(false), 500); // Animation duration
            return () => clearTimeout(timer);
        }
    }, [animateScore]);

    const handleContinue = () => {
        copingStatementStore.goToNextStep();
    };

    const handleBackToCopingStatement = () => {
        copingStatementStore.goToStep(REPEAT_COPING_STATEMENT_STEP);
    };

    const handleRestart = () => {
        resetGame();
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: `${pxToRem(12)} ${pxToRem(16)}`,
                overflow: 'hidden',
                position: 'relative',
            }}
            data-qa-id="focus-activity-step"
        >
            {/* Back and End buttons */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={onEnd}
                    sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    marginTop: 2,
                    paddingBottom: 2,
                }}
                data-qa-id="game-content-container"
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, // Add gap between cards
                    }}
                >
                    {/* Top Card */}
                    <ColorCard
                        word={topCardWord}
                        color="Black"
                        backgroundColor={topCardBackgroundColor}
                        textColor={topCardTextColor}
                        dataQaId="top-card"
                        isTextHidden={isTopTextHidden}
                    />

                    {/* Bottom Card */}
                    <ColorCard
                        word={bottomCardWord}
                        color={bottomCardTextColor}
                        dataQaId="bottom-card"
                        isTextHidden={isBottomTextHidden}
                    />

                    {/* Feedback Icon overlaid */}
                    {answerFeedback && (
                        <FeedbackIcon isCorrect={answerFeedback === 'correct'} />
                    )}
                </Box>

                {/* Score Counter */}
                {!gameOver && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 2,
                        }}
                    >
                        <StarIcon sx={{ color: 'black', marginRight: 1 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Score:
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                animation: animateScore ? `${scoreIncrease} 0.5s` : 'none',
                                marginLeft: 0.5,
                            }}
                        >
                            {score}
                        </Typography>
                    </Box>
                )}

                {/* Timer */}
                <Box
                    sx={{
                        width: isDesktop ? '80%' : '100%',
                        marginTop: 2,
                        minHeight: pxToRem(40),
                    }}
                >
                    {timeLeft !== null && !gameOver ? (
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <AccessTimeIcon sx={{ marginRight: 1 }} />
                            </Box>
                            <LinearProgress
                                variant="determinate"
                                value={(timeLeft / timerDuration) * 100}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ height: pxToRem(40) }}></Box>
                    )}
                </Box>

                {/* Instructional text */}
                <Typography
                    variant="h6"
                    sx={{
                        marginTop: 2,
                        textAlign: 'center',
                        opacity: 0.8,
                        marginBottom: 2,
                    }}
                >
                    {gameOver
                        ? `${gameOverReason === 'timeout' ? 'Ran out of time. ' : ''
                        }${score > 0 ? 'Congrats. ' : ''}Your final score is ${score}.`
                        : 'Does the meaning of the top card match the text color of the bottom card?'}
                </Typography>

                {/* Controls */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: gameOver ? 'column' : 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        gap: 2,
                    }}
                >
                    {gameOver ? (
                        gameOverReason === 'timeout' && !showGameOverButtons ? (
                            null
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: isDesktop ? '80%' : '100%',
                                }}
                                data-qa-id="options-container"
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleRestart}
                                    sx={{
                                        backgroundColor: 'black',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        width: '100%',
                                        '&:hover': {
                                            backgroundColor: 'black',
                                        },
                                    }}
                                >
                                    Try Again
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleBackToCopingStatement}
                                    sx={{
                                        backgroundColor: 'black',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        width: '100%',
                                        marginTop: 2,
                                        '&:hover': {
                                            backgroundColor: 'black',
                                        },
                                    }}
                                >
                                    Back to Coping Statement
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleContinue}
                                    sx={{
                                        backgroundColor: 'black',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        width: '100%',
                                        marginTop: 2,
                                        '&:hover': {
                                            backgroundColor: 'black',
                                        },
                                    }}
                                >
                                    Continue
                                </Button>
                            </Box>
                        )
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: isDesktop ? '80%' : '100%',
                                marginTop: 2,
                            }}
                            data-qa-id="yes-no-container"
                        >
                            <Button
                                variant="contained"
                                onClick={() => handleAnswer(true)}
                                sx={{
                                    backgroundColor: 'black',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    flex: 1,
                                    '&:hover': {
                                        backgroundColor: 'black',
                                    },
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => handleAnswer(false)}
                                sx={{
                                    backgroundColor: 'black',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    marginLeft: isDesktop ? 4 : 2,
                                    flex: 1,
                                    '&:hover': {
                                        backgroundColor: 'black',
                                    },
                                }}
                            >
                                No
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

FocusActivityStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default FocusActivityStep;

import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";
import Main from "./Main";
import Header, { MobileHeader } from "./header";
import NavVertical from "./nav/NavVertical";
import NavHorizontal from "./nav/NavHorizontal";
import { useStoresContext } from "../../stores/useStoresContext";
import {
    ALL_USERS_CHANNEL,
    REFRESH_ALL_USERS,
    REFRESH_USER,
    UPDATED_UNREAD_MESSAGE_COUNT,
} from "../../constants/ably-events";
import apiLayer from "../../services/api-layer/ApiLayer";
import { useAuthContext } from "../../auth/useAuthContext";
import { useChannel } from "../../hooks/useChannel";
import AuthModal from "../../components/auth-modal/AuthModal";
import AuthEntry from "../../sections/auth/auth-entry";
import Register from "../../sections/auth/register";
import { pxToRem } from "../../theme/typography";
// config
import { NAV } from "../../config-global";
import { handleError } from "../../utils/handleError";
import { NEW_USER, NEW_USER_UNCONFIRMED, RETURNING_USER } from '../../constants/unauthenticated-user-reasons';
import MobileBottomBar from "./bottom/MobileBottomBar";

const filename = "/layouts/app/AppLayout.js";

const gridBackground = {
    backgroundImage: `
    linear-gradient(to right, ${alpha("#A1D7FF", 0.7)} ${pxToRem(0.6)}, transparent ${pxToRem(0.6)}),
    linear-gradient(to bottom, ${alpha("#A1D7FF", 0.7)} ${pxToRem(0.6)}, transparent ${pxToRem(0.6)})
  `,
    backgroundSize: "20px 20px",
};

const AppLayout = observer(() => {
    const { layoutStore } = useStoresContext();
    const { user, isAuthenticated, refreshUser } = useAuthContext();
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [openNav, setOpenNav] = useState(true);
    const isDesktop = useResponsive("up", "lg");
    const currentUserId = user?.user_id;

    const [_channel, _ably] = useChannel({
        channelName: ALL_USERS_CHANNEL,
        eventNames: [REFRESH_ALL_USERS],
        callbackOnMessageMap: {
            [REFRESH_ALL_USERS]: async (message, _channel) => {
                console.log("REFRESHING USER AFTER STREAK UPDATE: ");
                await refreshUser();
            },
        },
    });

    const [_userChannel, _userChannelAbly] = useChannel({
        channelName: `user-${currentUserId}`,
        eventNames: [REFRESH_USER, UPDATED_UNREAD_MESSAGE_COUNT],
        callbackOnMessageMap: {
            [REFRESH_USER]: async (message, _channel) => {
                const data = message.data;
                if (+currentUserId === +data.refresh_user_id) {
                    await refreshUser();
                }
            },
            [UPDATED_UNREAD_MESSAGE_COUNT]: (message, _channel) => {
                const data = message.data;
                if (+data.user_id === +currentUserId) {
                    setUnreadMessageCount(+data.unreadMessageCount);
                }
            },
        },
    });

    useEffect(() => {
        const getInitialUnreadMessageCount = async () => {
            if (!isAuthenticated) return;

            try {
                setIsFetching(true);
                const unreadMessageCountRes = await apiLayer.getUnreadMessageCount();
                if (unreadMessageCountRes.data.err) {
                    handleError({
                        error: unreadMessageCountRes.data.err,
                        message: "Error in getInitialUnreadMessageCount",
                        filename,
                        functionName: "getInitialUnreadMessageCount",
                    });
                }
                const unreadMessageCount = +unreadMessageCountRes.data.count;
                setUnreadMessageCount(unreadMessageCount);
            } catch (err) {
                handleError({
                    error: err,
                    message: "Error getting unread message count",
                    filename,
                    functionName: "getInitialUnreadMessageCount",
                });
            } finally {
                setIsFetching(false);
            }
        };
        getInitialUnreadMessageCount();
    }, [isAuthenticated]);

    const handleModalClose = () => {
        layoutStore.closeAuthModal();
    };

    const renderModalContent = () => {
        if (layoutStore.modalContent === "auth") {
            return <AuthEntry />;
        }
        if (layoutStore.modalContent === "register") {
            return <Register phone={layoutStore.registrationPhone} />;
        }
        return null;
    };

    const handleToggleNav = () => {
        setOpenNav(!openNav);
    };

    const handleAuthModalOpen = () => {
        layoutStore.openAuthModal({ flowType: "auth", isUserNew: RETURNING_USER });
    };

    const handleRegisterModalOpen = () => {
        layoutStore.openAuthModal({ flowType: "auth", isUserNew: NEW_USER_UNCONFIRMED });
    };

    if (!isDesktop) {
        return (
            <>
                {!layoutStore.shouldHideAppHeader && (
                    <MobileHeader />
                )}
                <Main
                    isNavHorizontal={true}
                    hideTopPadding={layoutStore.shouldHideAppHeader}
                    hideBottomPadding={layoutStore.shouldHideHorizontalNav}
                    sx={gridBackground}
                >
                    <Outlet />
                </Main>
                {!isAuthenticated && (
                    <MobileBottomBar>
                        <Stack
                            flexGrow={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={{ xs: 1, sm: 1.5 }}
                        >
                            <>
                                <Button variant="outlined" size={"small"} onClick={handleAuthModalOpen}>
                                    Sign In
                                </Button>
                                <Button variant="contained" size={"small"} onClick={handleRegisterModalOpen}>
                                    Create Account
                                </Button></>
                        </Stack>
                    </MobileBottomBar>
                )}
                {isAuthenticated && !layoutStore.shouldHideHorizontalNav && (
                    <NavHorizontal unreadMessageCount={unreadMessageCount} />
                )}
                <AuthModal open={layoutStore.isAuthModalOpen} onClose={handleModalClose}>
                    {renderModalContent()}
                </AuthModal>
            </>
        );
    }

    return (
        <>
            {!layoutStore.shouldHideAppHeader && (
                <Header isAuthenticated={isAuthenticated} sx={gridBackground} openNav={openNav} />
            )}
            <Box
                sx={{
                    display: { lg: "flex" },
                    height: "100%",
                }}
                data-qa-id={"app-layout-container"}
            >
                <NavVertical unreadMessageCount={unreadMessageCount || 0} openNav={openNav} onToggleNav={handleToggleNav} />
                <Main hideTopPadding={layoutStore.shouldHideAppHeader} sx={gridBackground}>
                    <Outlet />
                </Main>
            </Box>
            <AuthModal
                open={layoutStore.isAuthModalOpen}
                onClose={handleModalClose}
                customStyles={{
                    marginLeft: openNav ? pxToRem(NAV.W_DASHBOARD) : pxToRem(NAV.W_DASHBOARD_MINI),
                    marginRight: 0,
                    maxWidth: pxToRem(760),
                }}
            >
                {renderModalContent()}
            </AuthModal>
        </>
    );
});

export default AppLayout;

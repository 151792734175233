import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import useResponsive from '../../../hooks/useResponsive';
import { useSpring, animated, useTransition } from '@react-spring/web';

const phases = [
    { label: 'Inhale', duration: 4000 },
    { label: 'Hold', duration: 4000 },
    { label: 'Exhale', duration: 6000 },
];

const DeepBreathStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();
    const isDesktop = useResponsive('up', 'lg');

    const handleContinue = () => {
        copingStatementStore.goToNextStep();
    };

    // State variables
    const [phase, setPhase] = useState('');
    const [isBreathing, setIsBreathing] = useState(false);
    const [showInitialText, setShowInitialText] = useState(true);
    const [showContinueButton, setShowContinueButton] = useState(false);

    // Circle size range, memoized to prevent unnecessary re-renders
    const minSize = useMemo(() => (isDesktop ? 150 : 120), [isDesktop]);
    const maxSize = useMemo(() => (isDesktop ? 300 : 250), [isDesktop]);

    // Animation spring for circle size
    const [styles, api] = useSpring(() => ({
        size: minSize,
        config: { duration: phases[0].duration },
    }));

    // Transition for phase text
    const transitions = useTransition(phase, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
    });

    // Breathing sequence
    useEffect(() => {
        if (!isBreathing) return;

        let isMounted = true;

        const breathingSequence = async () => {
            // Delay before starting the breathing animation
            await new Promise((resolve) => setTimeout(resolve, 500));

            while (isMounted) {
                for (let i = 0; i < phases.length; i++) {
                    const currentPhase = phases[i];
                    setPhase(currentPhase.label);

                    // Update circle size for Inhale and Exhale
                    if (currentPhase.label === 'Inhale') {
                        api.start({
                            size: maxSize,
                            config: {
                                duration: currentPhase.duration,
                                easing: (t) => t,
                            },
                        });
                    } else if (currentPhase.label === 'Exhale') {
                        api.start({
                            size: minSize,
                            config: {
                                duration: currentPhase.duration,
                                easing: (t) => t,
                            },
                        });
                    } else if (currentPhase.label === 'Hold') {
                        // Keep the circle size constant during Hold
                        api.start({
                            size: styles.size.get(),
                            config: { duration: currentPhase.duration },
                        });
                    }

                    await new Promise((resolve) => setTimeout(resolve, currentPhase.duration));
                }
                // after first loop, show continue button
                if (!showContinueButton) {
                    setShowContinueButton(true);
                }
            }
        };

        breathingSequence();

        return () => {
            isMounted = false;
        };
    }, [isBreathing, api, minSize, maxSize, styles.size]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="deep-breath-step"
        >
            {/* Top bar with End button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={onEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
                data-qa-id="content-container"
            >
                {/* Initial Text */}
                {showInitialText && (
                    <Typography
                        variant={isDesktop ? 'h3' : 'h4'}
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: 4,
                            position: 'absolute',
                            top: "16%",
                        }}
                        data-qa-id="initial-text"
                    >
                        Let's start by taking a deep breath.
                    </Typography>
                )}

                {/* Animated Circle */}
                <animated.div
                    style={{
                        width: styles.size.to((s) => `${s}px`),
                        height: styles.size.to((s) => `${s}px`),
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent white
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                        cursor: !isBreathing ? 'pointer' : 'default',
                    }}
                    data-qa-id="breathing-circle"
                    onClick={() => {
                        if (!isBreathing) {
                            setIsBreathing(true);
                            setShowInitialText(false); // Hide initial text when breathing starts
                        }
                    }}
                >
                    {!isBreathing ? (
                        <Button
                            variant="text"
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'none',
                                fontSize: isDesktop ? '2rem' : '1.5rem',
                            }}
                            data-qa-id="start-button"
                        >
                            Start
                        </Button>
                    ) : (
                        transitions((style, item) => (
                            <animated.div style={{ ...style, position: 'absolute' }}>
                                <Typography
                                    variant={isDesktop ? 'h3' : 'h4'}
                                    sx={{
                                        fontWeight: 'bold',
                                        textShadow: '1px 1px 2px rgba(255,255,255,0.7)',
                                        textAlign: 'center',
                                    }}
                                    data-qa-id="breathing-phase-text"
                                >
                                    {item}
                                </Typography>
                            </animated.div>
                        ))
                    )}
                </animated.div>
            </Box>

            {/* Continue Button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    width: isDesktop ? "50%" : "100%",
                    margin: "auto",
                }}
                data-qa-id="continue-button-container"
            >
                <Button
                    variant="contained"
                    fullWidth={true}
                    disabled={!showContinueButton}
                    onClick={handleContinue}
                    sx={{
                        backgroundColor: 'black',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        opacity: showContinueButton ? 1 : 0,
                        '&:hover': {
                            backgroundColor: 'black',
                        },
                    }}
                    data-qa-id="continue-button"
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
});

DeepBreathStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default DeepBreathStep;

// components/coping-statement-steps/RepeatCopingStatementStep.jsx
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import { useSprings, animated } from '@react-spring/web';
import { useTransition } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import debounce from 'lodash.debounce';
import useResponsive from '../../../hooks/useResponsive';
import { pxToRem } from '../../../theme/typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/*
    TODO:
        - Make box area bigger for swiping
        - For desktop, use clickable arrows
*/

const RepeatCopingStatementStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();
    const copingStatements = copingStatementStore.getCopingStatements();

    const isDesktop = useResponsive('up', 'lg');

    const [currentIndex, setCurrentIndex] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    // State variables for instruction text
    const [instructionText, setInstructionText] = useState('(try saying this aloud)');
    const [showInstructionText, setShowInstructionText] = useState(true);

    // Handle window resize
    useEffect(() => {
        const handleResize = debounce(() => setWidth(window.innerWidth), 100);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, []);

    // Update instruction text after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            // Start fade-out of current instruction text
            setShowInstructionText(false);

            // After fade-out, change the instruction text and fade it in
            const fadeInTimer = setTimeout(() => {
                setInstructionText(isDesktop ? 'use arrows for a different coping statement' : 'swipe left for a different coping statement');
                setShowInstructionText(true);
            }, 500); // Duration matches the fade-out duration

            // Cleanup for fadeInTimer
            return () => clearTimeout(fadeInTimer);
        }, 5000);

        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, [isDesktop]);

    // Create transition for instruction text
    const transitions = useTransition(showInstructionText, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 }, // Duration of the opacity transition
    });

    // Create springs for each coping statement
    const [springs, api] = useSprings(copingStatements.length, (i) => ({
        x: (i - currentIndex) * width,
        display: 'block',
    }));

    // Update springs when currentIndex or width changes
    useEffect(() => {
        api.start((i) => ({
            x: (i - currentIndex) * width,
            display: 'block',
        }));
    }, [currentIndex, api, width]);

    // Gesture handling for mobile
    const bind = useDrag(
        ({
            active,
            movement: [mx],
            direction: [dx],
            distance: [distX],
            velocity: [velocityX],
        }) => {
            if (isDesktop) return; // Disable swiping on desktop

            // Define thresholds
            const distanceThreshold = width / 4; // 25% of screen width
            const velocityThreshold = 0.3; // Minimum velocity

            // Determine if swipe should trigger
            const trigger = distX > distanceThreshold || velocityX > velocityThreshold;

            if (active) {
                // Update springs during drag
                api.start((i) => {
                    if (i < currentIndex - 1 || i > currentIndex + 1) {
                        return { display: 'none' };
                    }
                    const x = (i - currentIndex) * width + mx;
                    return { x, display: 'block' };
                });
            } else {
                if (trigger) {
                    if (dx > 0 && currentIndex > 0) {
                        // Swipe right to previous
                        setCurrentIndex((prev) => prev - 1);
                    } else if (dx < 0 && currentIndex < copingStatements.length - 1) {
                        // Swipe left to next
                        setCurrentIndex((prev) => prev + 1);
                    }
                }
                // Snap back to position
                api.start((i) => ({
                    x: (i - currentIndex) * width,
                    display: 'block',
                }));
            }
        },
        {
            axis: 'x',
            filterTaps: true,
            swipe: { distance: [50, 50] },
        }
    );

    const handleContinue = () => {
        copingStatementStore.goToNextStep();
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < copingStatements.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                overflow: 'hidden',
                position: 'relative',
            }}
            data-qa-id="repeat-coping-statement-step"
        >
            {/* Top bar with End button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={onEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    End
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
                data-qa-id="content-container"
            >
                {isDesktop && currentIndex > 0 && (
                    <IconButton
                        onClick={handlePrev}
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                        }}
                        data-qa-id="prev-button"
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                )}

                {springs.map(({ x, display }, i) => (
                    <animated.div
                        key={i}
                        {...(!isDesktop && bind())}
                        style={{
                            display,
                            x,
                            touchAction: 'pan-y',
                            position: 'absolute',
                            width: '100%',
                            // add enormous padding for better mobile swipe range
                            padding: isDesktop ? "inherit" : "80% 0",
                        }}
                    >
                        <Typography
                            variant="h2"
                            align="center"
                            sx={{
                                fontWeight: '600',
                                margin: 4,
                            }}
                            data-qa-id={`coping-statement-text-${i}`}
                        >
                            {copingStatements[i]}
                        </Typography>
                    </animated.div>
                ))}

                {isDesktop && currentIndex < copingStatements.length - 1 && (
                    <IconButton
                        onClick={handleNext}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                        }}
                        data-qa-id="next-button"
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                )}
            </Box>

            {/* Instruction */}
            <Box
                sx={{
                    marginTop: 2,
                    flexGrow: 1,
                    minHeight: pxToRem(50),
                }}
                data-qa-id="instruction-text-container"
            >
                {transitions(
                    (style, item) =>
                        item && (
                            <animated.div style={style}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: isDesktop ? pxToRem(20) : "inherit",
                                        opacity: 0.8,
                                    }}
                                    align="center"
                                    data-qa-id="instruction-text"
                                >
                                    {instructionText}
                                </Typography>
                            </animated.div>
                        )
                )}

                {isDesktop && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 4,
                        }}
                        data-qa-id="continue-button-container"
                    >
                        <Button
                            variant="contained"
                            onClick={handleContinue}
                            sx={{
                                backgroundColor: 'black',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            Continue
                        </Button>
                    </Box>
                )}
            </Box>

            {!isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleContinue}
                        sx={{
                            backgroundColor: 'black',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue
                    </Button>
                </Box>
            )}
        </Box>
    );
});

RepeatCopingStatementStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default RepeatCopingStatementStep;
